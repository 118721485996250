/* eslint-disable camelcase */
/* eslint-disable import/namespace */
import { queryClient } from "config"
import { find, get, isArray, keys, toNumber } from "lodash"
import { Suspense } from "react"
import api from "services/api"
import notification from "services/notification"
import constants from "store/constants"
import yupLocale from "store/yupLocale"
import * as yup from "yup"

yup.setLocale(yupLocale)

function checkStatus(status) {
  let statusType = "ok"
  if (status < 200 && status > 299) statusType = "error"
  return statusType
}

function toReadable(number) {
  return new Intl.NumberFormat("en-US").format(number || "")
}

const validateForm = ({ fields }) => {
  const schema = {}

  if (isArray(fields))
    fields.forEach((field) => {
      const { name = "" } = field

      switch (get(field, "type")) {
        case "number":
          schema[name] = yup.number().typeError("Raqam kiritilishi kerak")
          break
        case "object": {
          if (get(field, "nullable")) {
            schema[name] = yup.object().nullable()
          } else {
            schema[name] = yup.object().typeError("Malumot tanlanishi kerak")
          }
          break
        }
        case "boolean":
          schema[name] = yup.boolean()
          break
        case "array":
          schema[name] = yup.array()
          break
        case "email":
          schema[name] = yup
            .string()
            .email("link https://example.uz/any ko'rinishida bo'lishi kerak")
          break
        case "url":
          schema[name] = yup.string().url()
          break
        case "json":
          schema[name] = yup.string().test("valid", "Json formatida kiriting", (value) => {
            try {
              JSON.stringify(JSON.parse(value))
              return true
            } catch (e) {
              return false
            }
          })
          break
        default: {
          schema[name] = yup.string()
        }
      }
      if (get(field, "required")) {
        schema[name] = schema[name].required()
      }
      if (!get(field, "required") && get(field, "type") === "object") {
        schema[name] = schema[name].nullable()
      }
      if (get(field, "type") === "boolean" && get(field, "required")) {
        schema[name] = schema[name].test(
          "is boolean",
          "Maydon belgilanishi kerak",
          (value) => value === true,
        )
      }
      if (get(field, "min"))
        schema[name] = schema[name].min(
          get(field, "min"),
          `Minimal qiymat ${toReadable(get(field, "min"))}`,
        )
      if (get(field, "max")) schema[name] = schema[name].max(get(field, "max"))
      if (get(field, "nullable")) schema[name] = schema[name].nullable()
    })
  return yup.object(schema)
}

const sortByCreator = (setSort) => (col, sort) => {
  if (sort === `-${col}`) {
    setSort(`+${col}`)
  } else {
    setSort(`-${col}`)
  }
}

function filterRoutes(routes) {
  const permissions = get(queryClient.getQueryData(["globalState"]), "user.permissions")
  const isSuperuser =
    get(queryClient.getQueryData(["globalState"]), "user.is_superuser") ||
    get(queryClient.getQueryData(["globalState"]), "user.is_superadmin")

  const newRoutes = []

  routes.forEach(({ children }, i) => {
    const permittedRoutes =
      Array.isArray(permissions) && isArray(children)
        ? children.filter((route) => {
            const { permission } = route
            if (Array.isArray(permission)) {
              return (
                Array.isArray(permissions) && permissions.some((item) => permission.includes(item))
              )
            }
            return (
              Array.isArray(permissions) &&
              (permissions.includes(route.permission) || route.permission === "profile")
            )
          })
        : []

    // get routes that has children
    if (permittedRoutes.length > 0) newRoutes.push({ ...routes[i], children: permittedRoutes })
  })

  if (isSuperuser) return routes

  return newRoutes
}

function filterNav(navs) {
  const permissions = get(queryClient.getQueryData(["globalState"]), "user.permissions")
  const isSuperuser =
    get(queryClient.getQueryData(["globalState"]), "user.is_superuser") ||
    get(queryClient.getQueryData(["globalState"]), "user.is_superadmin")

  if (isSuperuser) return navs
  return navs.filter(
    ({ permission }) => Array.isArray(permissions) && permissions.includes(permission),
  )
}

function checkPermissions(list = []) {
  const permissions = get(queryClient.getQueryData(["globalState"]), "user.permissions")
  const isSuperuser =
    get(queryClient.getQueryData(["globalState"]), "user.is_superuser") ||
    get(queryClient.getQueryData(["globalState"]), "user.is_superadmin")

  const avaliablePermissions = list.filter(
    (item) => Array.isArray(permissions) && permissions.includes(item),
  )

  if (isSuperuser) return true

  return avaliablePermissions.length > 0
}

function copy(text) {
  let textArea

  function isOS() {
    return navigator.userAgent.match(/ipad|iphone/i)
  }

  function createTextArea(value) {
    textArea = document.createElement("textArea")
    textArea.value = value
    document.body.appendChild(textArea)
  }

  function selectText() {
    let range
    let selection

    if (isOS()) {
      range = document.createRange()
      range.selectNodeContents(textArea)
      selection = window.getSelection()
      selection.removeAllRanges()
      selection.addRange(range)
      textArea.setSelectionRange(0, 999999)
    } else {
      textArea.select()
    }
  }

  function copyToClipboard() {
    try {
      document.execCommand("copy")
      document.body.removeChild(textArea)
      notification.success("Nusxa olindi")
    } catch (err) {
      //
    }
  }

  createTextArea(text)
  selectText()
  copyToClipboard()
}

function keyPress(e, key, cb) {
  if (e.key === key) cb(e)
}

function regionReducer(addresses) {
  return Array.isArray(addresses)
    ? addresses.reduce((acc, curr) => {
        const items = get(curr, "districts") || []
        const newItems = items.map((item) => ({
          ...item,
          value: get(item, "id"),
          label: `${get(curr, "name")},${get(item, "name")}`,
        }))
        return [...acc, ...newItems]
      }, [])
    : []
}

function preventEnter(e) {
  if (e.key === "Enter") e.preventDefault()
}

const withSuspense = (Component) => {
  const wrappedComponent = (props) => (
    <Suspense fallback={null}>
      <Component {...props} />
    </Suspense>
  )
  return wrappedComponent
}

function getFileTypeFromUrl(url) {
  const ext = url.split(".").pop()

  if (constants.imageTypes.includes(ext)) return "image"
  if (constants.videoTypes.includes(ext)) return "video"
  if (constants.audioTypes.includes(ext)) return "audio"
  if (constants.wordTypes.includes(ext)) return "doc"
  if (constants.excelTypes.includes(ext)) return "excel"
  if (constants.archiveTypes.includes(ext)) return "archive"

  return null
}

function getFilterValues(fields) {
  const fieldKeys = keys(fields)
  const newValues = fieldKeys.reduce((pv, cr) => {
    if (fields[cr] === "true") {
      return {
        ...pv,
        [cr]: true,
      }
    }
    if (fields[cr] === "false") {
      return {
        ...pv,
        [cr]: false,
      }
    }
    return {
      ...pv,
      [cr]: fields[cr],
    }
  }, {})
  return newValues
}

const checklistStorage = {
  get: () => {
    let data = []
    try {
      data = JSON.parse(localStorage.getItem("checklist"))
    } catch (e) {
      //
    }
    return data
  },
  set: (argument) => {
    try {
      if (typeof argument === "function") {
        const old = Array.isArray(checklistStorage.get()) ? checklistStorage.get() : []
        localStorage.setItem("checklist", JSON.stringify(argument(old)))
      } else {
        localStorage.setItem("checklist", JSON.stringify(argument))
      }
    } catch (e) {
      //
    }
  },
  add: (item) => {
    try {
      let old = checklistStorage.get()
      if (Array.isArray(old)) {
        if (!find(old, { id: get(item, "id") })) {
          old.push(item)
          checklistStorage.set(old)
        }
      } else {
        old = [item]
        checklistStorage.set(old)
      }
    } catch (e) {
      //
    }
  },
  remove: (data) => {
    try {
      const old = checklistStorage.get()
      if (Array.isArray(old)) {
        checklistStorage.set(old.filter((item) => get(item, "id") !== get(data, "id")))
      }
    } catch (e) {
      //
    }
  },
}

function permissionReducer(permissions) {
  return permissions ? permissions.reduce((prev, curr) => ({ ...prev, [curr]: true }), {}) : {}
}

function getAccountFromAccounts({ attribute, accounts: _accounts, type = "default" }) {
  const accounts =
    _accounts || get(queryClient.getQueryData(["globalState"]), "user.accounts") || []

  const account = find(accounts, {
    type,
  })

  if (attribute === "balance") return toReadable(get(account, attribute, 0))

  if (!attribute) return account

  return get(account, attribute, 0)
}

function getOrderNumber(text) {
  const match = text.match(/(?:o-)?(\d+)(?:-\d+)?/) // Supports optional 'o-'
  return match ? String(match[1]).replace("Escape", "") : null
}

function clearCacheDaily() {
  const clearedAt = localStorage.getItem("clearedAt")
  if (toNumber(clearedAt) + 86400000 > Date.now()) {
    return null
  }

  const token = localStorage.getItem("token")
  const scannedForCurier = localStorage.getItem("scannedForCurier")
  const user = localStorage.getItem("user")
  const appVersion = localStorage.getItem("app-version")

  localStorage.clear()

  if (token) localStorage.setItem("token", token)
  if (appVersion) localStorage.setItem("app-version", appVersion)
  if (user) localStorage.setItem("user", user)
  if (scannedForCurier) localStorage.setItem("scannedForCurier", scannedForCurier)

  localStorage.setItem("clearedAt", Date.now())
}

export default {
  clearCacheDaily,
  getOrderNumber,
  getAccountFromAccounts,
  permissionReducer,
  checklistStorage,
  getFilterValues,
  checkStatus,
  validateForm,
  sortByCreator,
  filterRoutes,
  filterNav,
  checkPermissions,
  toReadable,
  copy,
  keyPress,
  regionReducer,
  preventEnter,
  withSuspense,
  getFileTypeFromUrl,
}
