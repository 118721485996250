import { useQueryClient } from "@tanstack/react-query"
import { Icons } from "components"
import { useRequest } from "hooks"
import { get } from "lodash"
import { useLocation, useParams } from "react-router-dom"
import { helpers } from "services"
import FreeDeliveryCoin from "components/FreeDeliveryCoin"

import "./styles.scss"

function GiveCheck() {
  const { id } = useParams()
  const queryClient = useQueryClient()
  const loaction = useLocation()
  const data = queryClient.getQueryData([`orders/${id}`]) || {
    data: { data: queryClient.getQueryData(["orderOne"]) },
  }
  const items = get(data, "data.data.order_items") || []
  const productBaseUrl =
    process.env.REACT_APP_SHOP_PRODUCT_BASE_URL || "https://100k.uz/shop/product/"
  useRequest({
    url: `orders/${id}`,
    enabled: false,
  })

  useRequest({
    url: `orders/${id}/items`,
    enabled: false,
  })

  const shippingType = get(data, "data.data.price.shipping_price") > 0 ? "Alohida" : "Bepul"
  const discountPrice = get(data, "data.data.price.discount_price") || 0
  const paymentStatusLabel = get(data, "data.data.payment_status_label")

  const color = shippingType === "Alohida" ? "bg-green" : "bg-blue"

  return loaction.pathname.includes("order/update") ? (
    <div className="give-check give-check__bg" style={{ maxWidth: "400px" }}>
      <div className="status-card">
        <div>
          <div className="status-card__top">
            <h1>
              <strong>#{get(data, "data.data.id")}</strong> -{" "}
            </h1>
            <p className="status-text">{get(data, "data.data.status_label")}</p>
            {get(data, "data.data.packed_at") ? <Icons.Package className="packed" /> : null}
          </div>
        </div>
        <ul>
          {items.map((item) => (
            <li key={get(item, "id")}>
              <div className="title">
                <a
                  href={productBaseUrl + get(item, "product.slug")}
                  target="_blank"
                  rel="noreferrer"
                  className={`${
                    get(item, "product_item.quantity") === 0 ? "c-black txt-strong" : ""
                  }`}
                >
                  <FreeDeliveryCoin fee={get(item, "total_admin_fee") / get(item, "quantity")} />
                  {get(item, "product_title")}
                </a>{" "}
                - <span className="c-red">{get(item, "quantity")}ta</span>
              </div>
              <p className="c-red item-price">{helpers.toReadable(get(item, "price"))} so&apos;m</p>
            </li>
          ))}
          <li className={color}>
            <div className="title">
              <p>Dostavka turi</p>
            </div>
            <p>{shippingType}</p>
          </li>
          <li className={color}>
            <div className="title">
              <p>Dostavka summasi</p>
            </div>
            <p>{helpers.toReadable(get(data, "data.data.price.shipping_price"))}</p>
          </li>
          <li className={color}>
            <div className="title">
              <p>To'lov holati</p>
            </div>
            <p>{paymentStatusLabel}</p>
          </li>
          {discountPrice > 0 ? (
            <li className={color}>
              <div className="title">
                <p>Chegirma</p>
              </div>
              <p>{helpers.toReadable(-discountPrice)}</p>
            </li>
          ) : null}
        </ul>
      </div>
      <h1 className="price" key={get(data, "data.data.price.total")}>
        <span className="c-red">{helpers.toReadable(get(data, "data.data.price.total"))}</span>{" "}
        so&apos;m
      </h1>
    </div>
  ) : null
}

export default GiveCheck
